@import "theme/theme_variables";  // <--------- Add your variables into this file. Also add variables to overwrite Bootstrap or UnderStrap variables here
@import "assets/bootstrap4";// <--------- Loads Bootstrap3 or Bootstrap4. Change from /bootstrap3 to /bootstrap4 Watch out! just for testing in the moment!
@import "understrap/understrap";// <-------- Loads the UnderStrap defaults. Just a few classes to incorporate BS in WP

//Optional files - If you dont use the corresponding scripts/fonts comment em out
@import "assets/font-awesome"; // <------- Font Awesome Icon font
@import "assets/underscores"; // <------- Underscores media styles

// Any additional imported files //
@import "theme/outdatedbrowser";
@import "theme/theme";  // <--------- That's where you can add your own design. Thats your part!
@import "theme/contact-form7"; // Contact Form 7 - Bootstrap 4 support
@import "theme/font"; // Fonts
@import "theme/responsive_utilities"; // Additional bootstrap elements
@import "theme/apply_to_contract"; // Apply To Contract form
@import "hamburgers/hamburgers"; // Menu icons  PS Not as good as the local ones
