@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.eot');
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../fonts/HelveticaNeueLTPro-Bd.eot');
    src: url('../fonts/HelveticaNeueLTPro-Bd.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeueLTPro-Bd.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-Bd.woff') format('woff'),
        url('../fonts/HelveticaNeueLTPro-Bd.ttf') format('truetype'),
        url('../fonts/HelveticaNeueLTPro-Bd.svg#HelveticaNeueLTPro-Bd') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../fonts/HelveticaNeueLTPro-Lt.eot');
    src: url('../fonts/HelveticaNeueLTPro-Lt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
        url('../fonts/HelveticaNeueLTPro-Lt.woff') format('woff'),
        url('../fonts/HelveticaNeueLTPro-Lt.ttf') format('truetype'),
        url('../fonts/HelveticaNeueLTPro-Lt.svg#HelveticaNeueLTPro-Lt') format('svg');
    font-weight: 300;
    font-style: normal;
}
