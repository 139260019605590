.apply-to-contract-form {

  input[type=checkbox] {
    position: absolute;
    z-index: 1;
    top: 7px;
    left: 2px;
  }


  input[type=checkbox] ~ label span {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: -5px 0px 0 0;
    vertical-align: middle;
    background: url("../img/icon-set.png") -28px -103px no-repeat;
    cursor: pointer;
    z-index: 2;
 }

 input[type=checkbox]:checked ~ label span  {
   background: url("../img/icon-set.png") -4px -103px no-repeat;
 }

  input[type="text"],
  input[type="email"],
  select {

    &.error {
      color: #FF6C36;
      border: 2px solid #FF6C36;
      background: #fdf2ec;
    }

  }

  label {

    &.checkbox-label {
      margin: 0;
      line-height: 27px;
    }

    &.error {
      color: #FF6C36;
      margin-top: 5px;
    }

  }

  div.error {
    color: #FF6C36;
    margin-top: 5px;    
  }

  label[for="contract_ask_entitled_person_email"] {

    position: relative;
    padding-right: 35px;

  }

  .form-check {

    label.error {
      display: none !important;
    }

    label.error[style='display: inline-block;'] + label {
      color: #FF6C36;
    }

  }

  .success-message {
    color: green;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .error-message {
    color: #FF6C36;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  select {
    border: 1px solid #55565a;
    font-weight: 300;
    border-radius: 3px;
    background-color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 9px;
    color: #282828;
    height: 44px !important;
  }

  textarea {
    border: 1px solid #55565a;
    font-weight: 300;
    border-radius: 3px;
    background-color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 9px;
    color: #282828;
  }

  button {
    font-size: 14px;
    line-height: 16.8px;
    font-weight: 700;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 15px;
    padding: 14.5px 10px;
    color: #fff;
    background-color: #00b0b9;
    border-radius: 3px;
    min-width: 110px;
    letter-spacing: 1px;
    border: 0;
    cursor: pointer;
    margin-top: 0;

    &:hover {
      color: #fff;
      background-color: #33c2c8;
    }

  }

  .select2-container .select2-selection--single {
    height: 44px;
  }

  .lpaisais-wrapper {

    .form-group {
      display: flex;
      flex-direction: column;

      .select2-container {
        order: 1;
      }

      label.error {
        order: 2;
      }

      label.error[style='display: inline-block;'] + .select2-container .select2-selection {
        color: #FF6C36;
        border: 2px solid #FF6C36;
        background: #fdf2ec;
      }

      label.error[style='display: inline-block;'] + .select2-container .select2-selection--single {
        padding: 9px 9px;
      }

    }

    .addr_postcode,
    .addr_text,
    .addr_id {
      display: none;
    }

  }

  .ps_calc_info_tooltip {
    z-index: 10;

    @media (max-width: 600px) {

      .ps_calc_info_tooltip_content {
        width: 200px;
        right: 0;
        left: initial;
      }

    }

  }

}

.grecaptcha-badge {
  z-index: 1000;
}
