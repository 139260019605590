//Theme colors
$tc_black: #282828;
$tc_white: #fff;
$tc_orange: #FF6C36;
$tc_orange_hover: #FF8C5D;
$tc_mint: #00B0B9;
$tc_mint_hover: #33C2C8;

body{
    color: $tc_black;
    font-family: 'Helvetica Neue LT Pro', 'Roboto';
    line-height: 24px;
    font-size: 16px;
}

html{
    height: 100%;
}

h1{
    line-height: 40.8px;
    font-weight: bold;
    letter-spacing: 0.86px;
    margin-bottom: 17px;
}

h2{
    line-height: 40.8px;
    font-weight: 300;
    letter-spacing: 0.35px;

    .bold {
      font-weight: bold;
    }

}

h3{
    line-height: 27.6px;
    font-weight: 300;
}

h4{
    line-height: 22.8px;
    font-weight: 300;
}

h5{
    line-height: 16.8px;
    font-weight: bold;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    margin-top: 8px;
}

h5 a{
    padding: 14.5px 10px;
    color: #fff;
    background-color: $tc_mint;
    border-radius: 3px;
    min-width: 210px;
    display: block;
    letter-spacing: 1px;
}

h5 a:focus,
h5 a:hover{
    color: #fff;
    background-color: $tc_mint_hover;
}

p{
    letter-spacing: 0.38px;
}

ol {
    list-style-position: inside;
    padding: 0;
    margin-bottom: 30px;
    counter-reset: item;
}

ol > li {
    list-style-type: none;
    counter-increment: item;
    margin-bottom: 23px;
    letter-spacing: 0.16px;
    line-height: 19px;
}

ol > li:before {
    display: inline-block;
    width: 1.1em;
    padding-right: 0.5em;
    font-weight: bold;
    text-align: right;
    content: counter(item) ".";
}
/*Bullet style for lists
.content{
    ul {
        list-style: none;
        padding-left: 16px;
        line-height: 30px;
        letter-spacing: 0.39px;
        margin-bottom: 12px;
        li::before {
            content: "•";
            color: #00B0B9;
            display: inline-block;
            width: 0.9em;
            margin-left: -1em;
        }
    }
    p + ul {
        margin-top: -5px;
    }
}
*/
/*Tablet*/
/*
@media only screen and (max-width: 1280px) {
    .content{
        ul{
            line-height: 26px;
        }
    }
}
*/
/*Mobile*/
/*
@media only screen and (max-width: 767px) {
    .content{
        ul {
            list-style: none;
            padding-left: 16px;
            line-height: 23px;
            letter-spacing: 0.39px;
            margin-bottom: 12px;
            margin-left: -3px;
            li{
                margin-bottom: 0.32rem;
            }
        }
        p + ul {
            margin-top: -3px;
        }
    }
}
*/

.service-info-section,
.content{
    ul {
        padding-left: 16px;
        line-height: 30px;
        letter-spacing: 0.39px;
        margin-bottom: 12px;
        li{
            color: #00B0B9;
        }
    }
    p + ul {
        margin-top: -5px;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    .content{
        ul{
            line-height: 26px;
        }
    }
}
/*Mobile*/
@media only screen and (max-width: 767px) {
    .content{
        ul {
            padding-left: 16px;
            line-height: 23px;
            letter-spacing: 0.39px;
            margin-bottom: 12px;
            margin-left: -3px;
            li{
                margin-bottom: 0.32rem;
            }
        }
        p + ul {
            margin-top: -3px;
        }
    }
}

table thead td{
    background-color: $tc_mint;
}

table td{
    padding: 5px 10px;
    letter-spacing: 0.39px;
}


table td{
    letter-spacing: 0.39px;
}

h6{
    line-height: 52.8px;
    font-weight: 300;
}

a:hover,
a{
    color: inherit;
    text-decoration: none;
}

#page{
    overflow: hidden;
}

#page-wrapper p a {
  text-decoration:none;
  border-bottom: 2px solid $tc_mint;
  -webkit-transition: background 0.1s cubic-bezier(.33,.66,.66,1);
  -o-transition: background 0.1s cubic-bezier(.33,.66,.66,1);
  transition: background 0.1s cubic-bezier(.33,.66,.66,1);
}

#page-wrapper p a:hover {
  background: $tc_mint;
}

.wpcf7 input[type="email"],
.wpcf7 input[type=password],
.wpcf7 input[type=text],
input[type="email"],
input[type=password],
input[type="number"],
input[type=text]{
    border: 1px solid #55565A;
    font-weight: 300;
    border-radius: 3px;
    background-color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 9px;
    color: #282828;
    height: 44px;
}

.wpcf7 input[type="email"]:focus,
.wpcf7 input[type=password]:focus,
.wpcf7 input[type=text]:focus,
.wpcf7 textarea:focus,
input[type="email"]:focus,
input[type=password]:focus,
input[type=text]:focus,
input[type="number"]:focus,
textarea:focus,
.select2-selection:focus{
    outline: 0;
    border-color: #595A5B;
    -webkit-box-shadow: 0px 0px 0px 1px #595A5B;
    box-shadow: 0px 0px 0px 1px #595A5B;
}

input[type='number'] {
    -moz-appearance:textfield;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.select2-container .select2-selection--single {
    border: 1px solid #55565A;
    font-weight: 300;
    border-radius: 3px;
    background-color: #fff;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 9px;
    color: #282828;
    height: 46px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background-color: #00AFB9;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b{
    border-width: 7px 5px 0 5px;
    border-color: #231F20 transparent transparent transparent;
}

.select2-container--default .select2-selection--single .select2-selection__arrow{
    height: 42px;
    width: 38px;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #231F20 transparent;
    border-width: 0 5px 7px 5px;
}

.select2-container .select2-selection--single .select2-selection__rendered{
    padding-right: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 25px;
}

input::-webkit-input-placeholder {
    color:    #55565a;
}
input:-ms-input-placeholder {
   color:    #55565a;
}
input::-ms-input-placeholder {
   color:    #55565a;
}

input::placeholder {
   color:    #55565a;
   opacity: 1;
}

button:active,
button:focus{
    outline: 0;
    box-shadow: none;
}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    body{
        font-size: 14px;
    }

    h1{
        font-size: 28px;
        line-height: 33.6px;
        letter-spacing: 0.7px;
        margin-bottom: 8px;
    }

    h2{
        font-size: 28px;
        line-height: 33.6px;
    }

    h3{
        font-size: 20px;
        line-height: 24px;
    }

    h4{
        font-size: 17px;
        line-height: 20.4px;
    }

    h5{
        font-size: 14px;
        line-height: 16.8px;
        margin-top: 18px;
    }

    h5 a{
        padding: 14.5px 10px;
        color: #fff;
        background-color: $tc_mint;
        border-radius: 3px;
        min-width: 210px;
        display: block;
        letter-spacing: 1px;
    }

    h5 a:focus,
    h5 a:hover{
        color: #fff;
        background-color: $tc_mint_hover;
    }

    h6{
        font-size: 40px;
        font-weight: 300;
        line-height: 48px;
    }

    p{
        line-height: 22px;
    }

}

@media only screen and (max-width: 991px) {
    .container{
        padding-left: 8px;
        padding-right: 0px;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .container{
        padding-right: 37px;
        padding-left: 37px;
    }
}

@mixin btn-purple {
    h5 a{
        background-color: #823F98;
    }

    h5 a:focus,
    h5 a:hover{
        background-color: #9B66AD;
    }
}

@mixin btn-orange {
    h5 a{
        background-color: #FF6C36;
    }

    h5 a:focus,
    h5 a:hover{
        background-color: #FF8C5D;
    }
}

@mixin arrow($deg) {
    &:after{
        content: "";
        width: 7px;
        height: 7px;
        border: solid #757578;
        border-width: 0 2px 2px 0;
        display: inline-block;
        position: absolute;
        -webkit-transform: rotate($deg);
        -ms-transform: rotate($deg);
        transform: rotate($deg);
        @content;
    }
}

@mixin custom-radio-btn {//custom radio buttons
    input[type="radio"] {
        display:none;
    }
    input[type="radio"] + label span {
        display:inline-block;
        width:20px;
        height:20px;
        margin: -4px 4px 0 0;
        vertical-align:middle;
        background:url(../img/icon-set.png) -28px -50px no-repeat;
        cursor:pointer;
    }
    input[type="radio"]:checked + label span {
        background:url(../img/icon-set.png) -4px -50px no-repeat;
    }

}

@mixin custom-checkbox {//custom checkbox
    input[type="checkbox"] {
        display:none;
    }
    input[type="checkbox"] + label span {
        display:inline-block;
        width:20px;
        height:20px;
        margin: -4px 4px 0 0;
        vertical-align:middle;
        background:url(../img/icon-set.png) -28px -103px no-repeat;
        cursor:pointer;
    }
    input[type="checkbox"]:checked + label span {
        background:url(../img/icon-set.png) -4px -103px no-repeat;
    }

}

@mixin contacts-block() {//phone, email
    font-size: 19px;
    letter-spacing: 0.1px;
    .email{
        background-image: url(../img/icon-set.png);
        background-repeat: no-repeat;
        padding-left: 31px;
        background-position: -228px -2px;
        text-decoration: none;
        color: $tc_black;
        margin-right: 11px;
    }
    .email:hover{
        color: $tc_mint;
    }
    .phone_nr{
        background-image: url(../img/icon-set.png);
        background-repeat: no-repeat;
        margin-right: 11px;
        padding-left: 31px;
        background-position: -229px -26px;
        white-space: nowrap;
    }

    .facebook {
        background-image: url(../img/icon-set.png);
        background-repeat: no-repeat;
        padding-left: 31px;
        background-position: -229px -200px;
        white-space: nowrap;
    }

    /*Mobile*/
    @media only screen and (max-width: 767px) {
        .email,
        .phone_nr,
        .facebook {
            padding-bottom: 6px;
        }
    }
    @content;
}

@mixin clasic-h5 { //h5 without button styling
    h5 a {
        padding: 0;
        color: inherit;
        background-color: transparent;
        border-radius: 0;
        min-width: 0;
        display: block;
        border-bottom: 0 !important;
        letter-spacing: 1px;
    }

    h5 a:hover {
        background-color: transparent !important;
    }
}

@mixin submit-as-h5 { //style submit button as regular h5 button
        font-size: 14px;
        line-height: 16.8px;
        font-weight: bold;
        text-transform: uppercase;
        display: inline-block;
        text-align: center;
        margin-top: 8px;
        padding: 14.5px 10px;
        color: #fff;
        background-color: $tc_mint;
        border-radius: 3px;
        min-width: 210px;
        letter-spacing: 1px;
        border: 0;
        cursor: pointer;
    &:hover{
        color: #fff;
        background-color: $tc_mint_hover;
    }
}

@mixin submit-as-h5-orange { //style submit button as regular h5 button
        background-color: $tc_orange;
    &:focus,
    &:hover{
        background-color: $tc_orange_hover;
    }
}

@mixin submit-as-h5-purple { //style submit button as regular h5 button
        background-color: #823F98;
    &:focus,
    &:hover{
        background-color: #9B66AD;
    }
}

#wrapper-navbar{
    .navbar{
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        background-color: #fff;
        padding: 0;
        min-height: 73px;
    }
    .pre-navbar{
        min-height: 42px;
        background-color: #00b0b9;
        color: #fff;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;
        .navbar-contacts{
            a.email{
                color: #fff;
                background-position: -228px -126px;
            }
            .phone_nr{
                background-position: -229px -149px;
            }
            .facebook {
                background-position: -229px -174px;
            }
            a.email:hover,
            a:hover{
                color: #c9c8c9;
            }
        }
    }
    #navbarNavDropdown{
        a{
            font-size: 19px;
            font-weight: 300;
            color: $tc_black;
            white-space: nowrap;
        }
        .current-menu-item a,
        .current-page-ancestor a{
            color: $tc_mint;
        }
    }

    #navbarNavDropdown a:hover{
        color: $tc_mint;
    }

    .navbar-brand{
        padding: 0;
        margin-right: 2.4rem;
        max-width: 202px;
    }

    .navbar-login {
        height: 73px;
        width: 180px;
        overflow: hidden;
        .login-btn{
            text-align: center;
            text-decoration: none;
            color: #fff;
            font-size: 16px;
            white-space: nowrap;
            letter-spacing: 0.4px;
            padding: 26px 10px 29px 10px;
            background-color: $tc_orange;
            background-image: url(../img/icon-set.png);
            background-repeat: no-repeat;
            background-position: -48px 0px;
            vertical-align: text-bottom;
            width: 100%;
            display: block;
            line-height: 20px;
        }
        .login-btn:hover,
        .login-btn:focus{
            background-color: $tc_orange_hover;
            background-position: -48px -102px;
        }
        .login-form{
            display: none;
            background-color: #fff;
            padding: 15px 20px;
            position: absolute;
            top: 90px;
            right: 20px;
            z-index: 100;
            width: 100%;
            max-width: 285px;
            border: 1px solid #C9C8C9;
            -webkit-box-shadow: 2px 5px 2px 0px rgba(0,0,0,0.3);
            box-shadow: 2px 5px 2px 0px rgba(0,0,0,0.3);
            h3{
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 13px;
            }
            label{
                font-weight: bold;
                margin-bottom: 0px;
            }
            input{
                width: 100%;
            }
            .submit{
                @include submit-as-h5;
                @include submit-as-h5-orange;
            }
            .register-btn{
                text-align: center;
                margin-bottom: 0;
            }

            .register-btn a:hover,
            .register-btn a:focus{
                color: #F36C3C;
            }
            .buttons{
                margin-bottom: 4px;
            }
        }
    }

    .navbar-search{
        border-left: 1px solid #C9C8C9;
        height: 35px;
        padding-left: 7px;
        .submit{
            border: none;
            padding: 0;
            width: 40px;
            margin-right: 8px;
            background-color: transparent;
            background-image: url(../img/icon-set.png);
            background-repeat: no-repeat;
            background-position: -221px -49px;
            border-color: transparent;
            font-size: 0;
            line-height: 0;
            border-radius: 0;
            cursor: pointer;
        }

        .submit:focus,
        .submit:hover{
            background-image: url(../img/icon-set.png);
            background-position: -221px -87px;
        }

        input{
            display: block;
            width: 124px;
            letter-spacing: 0.4px;
            margin-bottom: 2px;
            /*padding: .375rem .75rem;*/
            padding: 0 .75rem;
            font-size: 16px;
            line-height: 1.5;
            color: #55575A;
            background-color: $tc_white;
            background-clip: padding-box;
            border: 0;
            border-radius: 0;
            height: 34px;
        }

        label{
            display: none;
        }

        input:active,
        input:focus,
        .submit:active,
        .submit:focus{
            -webkit-box-shadow: none;
                    box-shadow: none;
        }
    }

    .navbar-language{
        a{
            color: #00677f;
            font-size: 16px;
            text-decoration: none;
            padding: 9px 8px;
        }

        a:hover{
            color: #fff;
        }

        .wpml-ls-current-language a{
            color: #fff;
        }

        .wpml-ls-legacy-list-horizontal {
            padding: 0px 11px;
            clear: both;
            margin-right: 2px;
            white-space: nowrap;
        }
    }

    .navbar-contacts{
        @include contacts-block;
        margin-right: 11px;
        text-align: right;
        a:hover{
            color: $tc_mint;
        }
    }

    .hamburger{
        display: none;
    }

    .hamburger:focus,
    .hamburger:active{
        outline: 0;
    }

    .navbar-mobile{
        display: none;
        width: 100%;
        background-color: #fff;
        position: absolute;
        z-index: 100;
    }

    .search-btn{
        display: none;
    }

    @media only screen and (min-width: 1024px) {
        .navbar-mobile{
            display: none !important;
        }
    }

    @media (min-width: 768px){
        .navbar-expand-md .navbar-nav .nav-link {
            padding-right: 1.3rem;
            padding-left: 1.3rem;
        }
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1024px) {
    #wrapper-navbar{
        &.search-open{
            #searchform{
                width: auto;
            }
            .navbar-brand{
                min-width: auto;
            }
            .search-btn.submit{
                display: none;
            }
        }
        #searchform{
            width: 0;
            overflow: hidden;
        }
        .navbar-search{
            padding: 0;
        }
        .search-btn.submit{
            display: block;
            height: 40px;
            margin: 0;
        }
        .navbar-expand-md .navbar-nav .nav-link{
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
        .navbar-brand{
            min-width: 200px;
            margin-right: 0;
        }
        .navbar-nav{
            display: table;
            width: 100%;
            list-style: none;
            li{
                display: table-cell;
                text-align: left;
            }
            a{
                display: block;
            }
        }
    }
}

@media only screen and (max-width: 1060px) {
    #wrapper-navbar{
        .navbar .navbar-contacts{
            display: none;
        }
    }
}

/*Tablet*/
@media only screen and (max-width: 1023px) {
    #wrapper-navbar{
        .hamburger{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-right: 4px;
        }
        .navbar-search{
            .submit{
                width: 49px;
                height: 35px;
                background-position: -221px -51px;
                margin: 6px 4px 4px 0px;
                border-right: 1px solid #C9C8C9;
                &:hover{
                    background-position: -221px -89px;
                }
            }
        }

        .navbar .navbar-login{
            display: none;
        }

        #navbarNavDropdown #main-menu{
            display: none !important;
        }

        .navbar-mobile{
            padding: 34px 23px;
            padding-bottom: 7px;
            .navbarNavMobile{
                font-size: 17px;
                a{
                    letter-spacing: 0.2px;
                    padding: 0.75rem 1rem;
                    &:hover,
                    &:focus{
                        color: #00B0B9;
                    }
                }
            }
            .navbar-search,
            .navbar-language{
                display: none;
            }
            .navbar-login{
                margin-bottom: 9px;
                overflow: visible;
                height: auto;
                width: auto;
                .login-btn{
                    color: #ff6c36;
                    font-size: 17px;
                    background-color: transparent;
                    background-image: none;
                    padding: 0.75rem 1rem;
                    letter-spacing: 0.2px;
                    display: inline;
                    span:before{
                        content: "";
                        background: url(../img/icon-set.png) -30px -26px no-repeat;
                        padding-left: 22px;
                        height: 18px;
                        display: inline-block;
                    }
                }
            }
            .navbar-contacts-wrap{
                width: 97%;
                border-top: 1px solid #c9c8c9;
                margin: 0 1rem;
                margin-top: 7px;
                padding-top: 10px;
                .navbar-contacts{
                    display: inline-block;
                    padding: 0px 0;
                    max-width: 50%;
                    text-align: left;
                    line-height: 49px;
                    font-size: 16px;
                    letter-spacing: 0.7px;
                    margin-left: -4px;
                }
            }
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    #wrapper-navbar{
        .pre-navbar{
            display: none;
        }
        .navbar{
            min-height: 50px;
        }
        .navbar .navbar-language,
        .navbar .navbar-search{
            display: none;
        }
        .navbar-brand{
            max-width: 139px;
        }
        .hamburger{
             padding:15px 7px;
             .hamburger-box {
                 width:19px;
                 height:16px;
                 .hamburger-inner {
                  display:block;
                  top:50%;
                  margin-top:-1px;
                 }
                 .hamburger-inner,.hamburger-inner::before,.hamburger-inner::after {
                 width:19px;
                 height:2px;
                }
                .hamburger-inner::before {
                 top:-7px
                }
                .hamburger-inner::after {
                 bottom:-7px
                }
             }
             &.hamburger--slider .hamburger-inner {
              top:1px
             }
             &.hamburger--slider .hamburger-inner::before {
              top:7px;
             }
             &.hamburger--slider .hamburger-inner::after {
              top:14px
             }
             &.hamburger--slider.is-active .hamburger-inner {
              transform:translate3d(0, 7px, 0) rotate(45deg)
             }
             &.hamburger--slider.is-active .hamburger-inner::before {
              transform:rotate(-45deg) translate3d(-2.71429px, -5px, 0);
              opacity:0
             }
             &.hamburger--slider.is-active .hamburger-inner::after {
              transform:translate3d(0, -14px, 0) rotate(-90deg)
             }
        }
        .navbar-mobile{
            padding: 28px 23px;
             .navbar-language{
                 display: block;
                 .wpml-ls-legacy-list-horizontal{
                     border: 0;
                     padding: 0;
                 }
                 a{
                     color: #A6A6A8;
                     font-size: 14px;
                     padding: 9px 15px;
                 }

                 a:hover{
                     color: $tc_mint;
                 }

                 .wpml-ls-current-language a{
                     color: $tc_mint;
                 }
             }
             .navbar-search{
                display: block;
                margin: 13px 13px 19px 14px;
                border-bottom: 1px solid #c9c8c9;
                padding-bottom: 24px;
                border-left: 0;
                height: auto;
                padding-left: 0;
                 input[type=text]{
                    display: block;
                    width: 100%;
                    letter-spacing: 0.4px;
                    margin: 0;
                    line-height: 1.5;
                    border: 1px solid #c9c8c9;
                    border-right: 0;
                    font-weight: 300;
                    -webkit-border-top-left-radius: 3px;
                    -webkit-border-bottom-left-radius: 3px;
                    -moz-border-radius-topleft: 3px;
                    -moz-border-radius-bottomleft: 3px;
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    background-color: #fff;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 10px 9px;
                    color: #55575A;
                    height: 44px;
                 }
                 .input-group-btn {
                     border: 1px solid #c9c8c9;
                     border-left: 0;
                     font-weight: 300;
                     border-radius: 0;
                     -webkit-border-top-right-radius: 3px;
                    -webkit-border-bottom-right-radius: 3px;
                    -moz-border-radius-topright: 3px;
                    -moz-border-radius-bottomright: 3px;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                     padding: 0;
                     height: 44px;
                    .submit{
                        border: 0;
                        float: right;
                        width: 36px;
                    }
                }
             }
             .navbar-contacts{

             }
        }
    }
}

#wrapper-footer{
    padding: 26px 0;
    padding-bottom: 0;
}

.site-footer{
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.4px;

    a:hover{
        color: $tc_mint;
    }

    .footer-widget-1 .textwidget{
        font-size: 19px;
        letter-spacing: 0.2px;
        line-height: 23px;
    }

    .footer-menu{
        max-width: 136px;
        margin-top: 23px;
        display: inline-block;

        ul{
            margin: 0;
            padding: 0;
            margin-right: -15px;
        }

        a{
            text-decoration: none;
            color: $tc_black;
        }

        a:hover{
            color: $tc_mint;
        }

        li{
            list-style: none;
        }


    }
    .footer-contacts{
        @include contacts-block{
            float: right;
            margin-top: 26px;
        }
    }

    .developed-by{
        float: right;
        text-align: right;
        padding-top: 5px;
        padding-bottom: 5px;
        .dev-logo{
            width: 66px;
            height: 29px;
            background-image: url(../img/icon-set.png);
            background-repeat: no-repeat;
            background-position: 0px -76px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .developed-by,
    .legal{
        font-size: 13px;
        letter-spacing: 0.33px;
    }
    .map-link:after{
        content: "";
        background-image: url(../img/icon-set.png);
        background-repeat: no-repeat;
        background-position: -27px -177px;
        padding-right: 20px;
    }
    .map-link:hover:after{
        content: "";
        background-image: url(../img/icon-set.png);
        background-repeat: no-repeat;
        background-position: -3px -177px;
        padding-right: 20px;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    .site-footer{
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.4px;
        .footer-widget-1 .textwidget{
            font-size: 17px;
            letter-spacing: 0.1px;
            line-height: 23px;
        }

        .footer-menu{
            display: none;

        }
        .footer-contacts{
            font-size: 17px;
            letter-spacing: 0.2px;
            float: right;
            margin-top: 18px;
            .email{
                line-height: 50px;
            }
        }

        .developed-by{

        }

        .developed-by,
        .legal{

        }
        .map-link:after{

        }
        .map-link:hover:after{

        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .site-footer{
        .footer-widget-2{
            display: none
        }

        .footer-menu{
            display: none;

        }
        .footer-contacts{
            display: none;
        }

        .developed-by{
            float: left;
            margin-top: 6px;
            width: 100%;
        }
    }
}

#wrapper-footer-full{
    position: relative;
    min-height: 218px;
    background-color: #EDECEC;
    padding: 51px 0;
    @include btn-purple;

    .widget-title {
        margin-bottom: 15px;
    }

    .dec-img-1{
        position: absolute;
        width: 270px;
        height: 235px;
        left: 31px;
        top: 56px;
    }

    .dec-img-2{
        position: absolute;
        width: 286px;
        height: 259px;
        right: 29px;
        top: -147px;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    #wrapper-footer-full{
        min-height: 182px;
        padding: 34px 0;
        padding-bottom: 31px;
        .footer-widget {
            padding-left: 18px;
        }
    }
    .search{
        #wrapper-footer-full{
            min-height: auto;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {

}

.four-column-section{
    margin-bottom: 10px;
    margin-top: 51px;
    .title{
        display: block;
        width: 100%;
        margin-bottom: 39px;
        letter-spacing: 0.4px;
    }

    @include clasic-h5;

    a:hover{
        color: #00afb9;
    }

    img{
        margin-bottom: 23px;
    }

    p{
        text-align: justify;
        margin-bottom: 2px;
    }
    .col-lg-3{
        margin-bottom: 41px;
    }

    .col-lg-6{
        margin-bottom: 44px;
    }

    .col-lg-12{
        margin-bottom: 41px;
    }

}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    .four-column-section{
        .title{
            margin-bottom: 34px;
        }

        img{
            margin-bottom: 16px;
        }

        p{
            line-height: 20.3px;
        }

        h5{
            margin-top: 9px;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .four-column-section{
        margin-bottom: -2px;
        img{
            max-width: 110px;
            max-height: 91px;
        }

        h5{
            margin-top: 14px;
        }
        h3{
            height: auto !important;
        }
        .col-lg-3{
            margin-bottom: 34px;
        }
    }
}

.site-main{
    .dec-img-3{
        position: absolute;
        top: -36px;
        right: -406px;
    }
}

.slider-text{
    letter-spacing: 0.3px !important;
}

.region-section{
    margin-top: 52px;
    margin-bottom: 51px;
    .col{
        min-height: 493px;
        position: relative;
        img{
            position: absolute;
            right: 60px;
            top: 50%;
            -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
        }
        &:last-child img {
            right: 37px;
            top: 47%;
        }
        .region-description{
            position: absolute;
            bottom: 36px;
            left: 45px;
            h2{
                font-size: 44px;
                margin-bottom: 9px;
                letter-spacing: 0.4px;
            }
            p{
                margin: 0;
            }
            h5{
                margin-top: 12px;
            }
            @include clasic-h5;
        }
    }

    .col > a{
        width: 100%;
        height: 100%;
        position: absolute;
        margin: 0 -15px;
    }
    .color-blue{
        background-color: #B2E8EA;
        h5{
            color: #00B0B9;
        }
        &:hover{
            background-color: #85D1D6;
        }
    }
    .color-orange{
        background-color: #FFD5BF;
        h5{
            color: #FF6C36;
        }
        &:hover{
            background-color: #FAB797;
            img{
                display: none;
            }
            .hover-img{
                display: block;
            }
        }
        .hover-img{
            display: none;
        }
    }
    .color-purple{
        background-color: #DAC5E0;
        h5{
            color: #823F98;
        }
        &:hover{
            background-color: #C19FCB;
        }
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    .region-section{
        margin-top: 30px;
        margin-bottom: 32px;
        .col{
            min-height: 296px;
            &.color-blue{
                img{
                    right: 27px;
                    max-width: 160px;
                }
            }
            &.color-orange{
                img{
                    right: 29px;
                    max-width: 123px;
                }
            }
            &.color-purple{
                img{
                    max-width: 154px;
                }
            }
            &:last-child img {
                right: 28px;
                top: 50%;
            }
            .region-description{
                bottom: 25px;
                left: 37px;
                h2{
                    font-size: 40px;
                    margin-bottom: 11px;
                    letter-spacing: 0.4px;
                }
                p{
                    max-width: 180px;
                    font-size: 16px;
                }
                h5{
                    margin-top: 15px;
                    letter-spacing: 0.9px;
                }
            }
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .region-section{
        margin-bottom: 33px;
        .col{
            min-height: 277px;
            &.color-blue{
                img{
                    right: 37px;
                    max-width: 130px;
                }
            }
            &.color-orange{
                img{
                    right: 37px;
                    max-width: 101px;
                }
            }
            &.color-purple{
                img{
                    max-width: 123px;
                }
            }
            &:last-child img {
                right: 37px;
            }
            .region-description{
                p{
                    font-size: 14px;
                }
                h5{
                    margin-top: 10px;
                }
            }
        }
    }
}

.package-search{
    display: block;
    min-height: 188px;
    width: 100%;
    background-color: #e05327;
    padding: 0 38px;
    padding-top: 18px;
    h3{
        color: #fff;
        margin-bottom: 16px;
    }
    .price-calculator{
        input{
            width: 100%;
            letter-spacing: 0.4px;
        }
        .btn-submit{
            @include submit-as-h5;
            @include submit-as-h5-orange;
            margin-top: 20px;
        }
    }
}


@media only screen and (min-width: 1280px) {
    .package-search{
        display: block;
        background-color: rgba(224,83,39,0.8);
        min-height: 85px;
        top: 261px;
        right: 23.2%;
        max-width: 285px;
        padding: 17px 20px;
        padding-top: 15px;
        .price-calculator{
            input{
                width: 100%;
            }
            .btn-submit{

            }
        }
    }
}

@media only screen and (max-width: 1670px) {
    .package-search{
        right: 14.2%;
    }
}

/*Tablet*/
@media only screen and (max-width: 1279px) and (min-width: 767px) {
    .package-search{
        display: block;
        min-height: 85px;
        h3{
            display: inline-block;
            vertical-align: top;
            margin-top: 13px;
            margin-right: 18px;
        }
        .price-calculator{
            display: inline-block;
            width: 71.9%;
            input{
                width: 48.3%;
                display: inline-block;
                margin-right: 13px;
            }
            .btn-submit{
                @include submit-as-h5;
                @include submit-as-h5-orange;
                margin-top: 0px;
                display: inline-block;
                margin-right: 0;
            }
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .package-search{
        display: block;
    }
}

.price-check-bar-wrapper{
    background-color: #EDECEC;
    .price-check-bar{
        @include custom-radio-btn;
        min-height: 120px;
        .btn-submit{
            @include submit-as-h5;
            margin-left: 14px;
        }
        h4{
            display: inline-block;
            line-height: 78px;
        }
        .rb-latvia img{
            margin-top: 18px;
        }
        img{
            margin-left: 13px;
            float: right;
        }
        .title{
            display: inline-block;
            padding-top: 24px;
            margin-right: 33px;
            h1{
                margin-bottom: 2px;
            }
        }
        .price-calculator{
            display: inline-block;
            vertical-align: top;
            margin-top: 20px;
            .radio-btn-container {
                display: inline-block;
                height: 71px;
            }
            label{
                margin-right: 24px;
            }
        }
    }
}


/*Tablet*/
@media only screen and (max-width: 1279px) {
    .package-search-wrap{
        width: 100%;
    }
    .price-check-bar-wrapper{
        .price-check-bar{
            padding-right: 22px;
            .btn-submit{
                margin-left: 0px;
                margin-top: 27px;
                float: right;
            }
            h4{

            }
            img{
                margin-left: 0;
                float: none;
                display: block;
                position: inherit;
                -webkit-transform: translateY(0%);
                    -ms-transform: translateY(0%);
                        transform: translateY(0%);
            }
            .title{
                margin-right: 0px;
                width: 25.4%;
                padding-top: 34px;
                h1{

                }
            }
            .price-calculator{
                width: 74%;
                margin-top: 21px;
                .radio-btn-container {
                    width: 58%;
                    height: auto;
                    display: inline-block;
                }
                label{
                    padding-right: 5.8%;
                    vertical-align: bottom;
                    width: 32.333%;
                    margin-right: 0;
                    display: inline-block;
                    white-space: nowrap;
                    &.rb-latvia{
                        img{
                            max-width: 66px;
                            margin-left: 4px;
                            margin-bottom: 24px;
                        }
                    }
                    &.rb-baltics{
                        img{
                            max-width: 50px;
                            margin-left: 9px;
                            margin-bottom: 11px;
                        }
                    }
                    &.rb-world{
                        padding-right: 0px;
                        img{
                            max-width: 64px;
                            margin-bottom: 10px;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
/*Mobile*/
@media only screen and (max-width: 767px) {
    .price-check-bar-wrapper{
        .price-check-bar{
            min-height: 120px;
            padding-left: 30px;
            padding-right: 12px;
            padding-bottom: 19px;
            .btn-submit{
                margin-top: 3px;
                float: left;
            }
            .title{
                padding-top: 11px;
                margin-right: 0px;
                width: 100%;
                h1{
                    margin-bottom: 0px;
                }
            }
            .price-calculator{
                width: 100%;
                margin-top: -4px;
                .radio-btn-container {
                    width: 100%;
                }
                label{
                    &.rb-latvia{
                        img{
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}

.home-content{
    margin-top: 50px;
}

.page-template-page-homepage{
    .wrapper{
        padding-top: 0;
    }
}

.tracking-form{
    .btn-submit{
        @include submit-as-h5;
        @include submit-as-h5-orange;
        text-align: center !important;
        width: 100%;
        margin-top: 20px;
    }
    input[type=text]{
        width: 100%;
    }
}

.page-template-page-tracking{
    .wrapper{
        padding-top: 53px;
    }
    #tracking-iframe{
        margin-top: 3px;
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .page-template-page-tracking{
        #tracking-iframe{
            min-height: 793px;
        }
    }
}

@media only screen and (max-width: 992px) {
    .page-template-page-tracking{
        #tracking-iframe{
            min-height: 933px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .page-template-page-tracking{
        #tracking-iframe{
            min-height: 1089px;
        }
    }
}

.loader {
    border: 5px solid #f3f3f3;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 5px solid #555;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin: 0 auto;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.news-announcements{
    margin-top: 62px;
    .entry-header{
        &:hover{
            color: #F36C3C;
        }
        @include arrow(45deg){
            right: 12px;
            top: 19px;
        };
        &.content-open{
            @include arrow(-135deg){
                right: 13px;
                top: 24px;
            };
        }
        &:hover,
        &:focus{
            &:after{
                border-color:  #F36C3C;
            }
        }
        padding-right: 30px;
        font-size: 19px;
        letter-spacing: 0.2px;
        cursor: pointer;
        position: relative;
        .entry-icon,
        .entry-title,
        .entry-meta{
            display: inline-block;
            vertical-align: middle;
        }
        .entry-icon{
            margin: 14px 9px;
            margin-right: 5px;
            background-image: url(../img/icon-set.png);
            background-repeat: no-repeat;
            background-position: -25px -2px;
            width: 22px;
            height: 21px;
        }
        .entry-title{
            margin-left: 36px;
            margin-bottom: 0;
            width: 81%;
        }
    }

    .entry-content{
        display: none;
        margin-left: 180px;
        margin-top: -3px;
        margin-right: 22px;
        padding-bottom: 11px;
    }

    article{
        border-top: 1px dashed #757578;
    }

    article:last-of-type{
        border-bottom: 1px dashed #757578;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    .news-announcements{
        .entry-header{
            .entry-title{
                padding-left: 45px;
                margin-left: 0;
                margin-bottom: 0;
                width: 67%;
            }
        }

        .entry-content{
            margin-left: 0;
            margin-right: 0;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .news-announcements{
        .entry-header{
            padding-bottom: 11px;
            .entry-title{
                width: 100%;
            }
        }
    }
}

.page-template-page-faqs{
    .wrapper{
        padding-bottom: 58px;
    }
    .page-title{
        margin-top: 21px;
    }
    .thumbnail-img{
        position: absolute;
        top: 25px;
        right: -301px;
    }
}

.vacancy-announcements,
.faq-announcements{
    margin-top: 10px;
    .entry-header{
        min-height: 30px;
        padding-right: 30px;
        &:hover{
            color: #F36C3C;
        }
        @include arrow(45deg){
            right: 12px;
            top: 19px;
        };
        &.content-open{
            @include arrow(-135deg){
                right: 13px;
                top: 24px;
            };
        }
        &:hover,
        &:focus{
            &:after{
                border-color:  #F36C3C;
            }
        }
        font-size: 19px;
        letter-spacing: 0.2px;
        cursor: pointer;
        position: relative;
        .entry-icon,
        .entry-title,
        .entry-meta{
            display: inline-block;
            vertical-align: middle;
        }
        .entry-title{
            margin: 13px 0;
            width: 97%;
        }
    }

    .entry-content{
        &:after {
          content: "";
          display: table;
          clear: both;
        }
        display: none;
        margin-top: -3px;
        margin-right: 22px;
        padding-bottom: 11px;
    }

    article{
        border-top: 1px dashed #757578;
    }
    article:last-of-type{
        border-bottom: 1px dashed #757578;
    }
}

.search{
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    article{
        border-top: 1px dashed #757578;
        padding-top: 12px;
    }
    article:last-of-type{
        border-bottom: 1px dashed #757578;
    }

    .page-header{
        margin-top: 23px;
        margin-bottom: 15px;
        .page-title{
            margin-bottom: 27px;
        }
        h3{
            color: $tc_orange;
        }
    }

    .entry-header{
        .entry-title {
            margin-bottom: 3px;
        }
        h4{
            letter-spacing: 0.2px;
            margin-bottom: 3px;
        }
    }

    .entry-summary{
        p{
            margin-bottom: 10px;
        }
    }

    b, strong {
        font-weight: bold;
    }

    .alm-btn-wrap button{
        @include submit-as-h5;
        @include submit-as-h5-orange;
    }
    .alm-btn-wrap{
        text-align: center;
        margin-top: 27px;
        margin-bottom: 29px;
    }
    .alm-load-more-btn.done{
       display: none;
    }
    //Footer at bottom
    #wrapper-footer-full{
        margin-top: auto;
    }

    #page{
        overflow: inherit;
    }
}

#tracking-iframe{
    width: 100%;
    min-height: 734px;
    border: 0;
}

#fancybox-wrap{
    top: 130px !important;
    .fancybox-bg{
        display: none;
    }
    #fancybox-content{
        -webkit-box-shadow: 2px 5px 2px 0px rgba(0,0,0,0.3);
        box-shadow: 2px 5px 2px 0px rgba(0,0,0,0.3);
        border-color: #EDECEC;
        background-color: #EDECEC;
    }

    #fancybox-close{
        background-image: url(../img/icon-set.png);
        background-repeat: no-repeat;
        background-position: -2px -26px;
        width: 22px;
        height: 22px;
        top: 16px;
        right: 15px;
    }
}

.pop-up-box{
    .widget-title{
        margin-top: -6px;
        h1{
            margin-bottom: 8px;
        }
    }
}

.wpcf7{
    .wpcf7-not-valid{
        background-color: #FFF0EA !important;
        border-color: #F36C3C !important;
        -webkit-box-shadow: 0px 0px 0px 1px #F36C3C !important;
        box-shadow: 0px 0px 0px 1px #F36C3C !important;
    }
    .invalid-field,
    .wpcf7-not-valid-tip{
        color: #F36C3C !important;
        font-size: 16px;
        font-weight: 300;
    }
    .wpcf7-response-output{
        -webkit-box-sizing: border-box !important;
                box-sizing: border-box !important;
    }
    .wpcf7-validation-errors{
        display: none !important;
    }
}


#contact-box{
    max-width: 600px;
    margin: 24px;
    .wpcf7-form{
        .wpcf7-form-control-wrap{
            display: inline-block;
        }
        .wpcf7-not-valid-tip{
                position: absolute;
        }
        &.invalid {
            .wpcf7-form-control-wrap{
                margin-bottom: 10px;
            }
        }
        .phone-nr,
        .your-email,
        .company,
        .your-name{
            max-width: 285px;
        }

        .your-email,
        .your-name{
            margin-right: 25px;
        }

        .address{
            display: block;
            width: 100%;
        }

        label{
            width: auto;
            display: inline-block;
            font-weight: bold;
            letter-spacing: 0.4px;
            margin-bottom: 13px;
        }
        textarea{
            border-color: #58595B;
        }
        input{
            height: 47px;
            border-color: #58595B;
        }
        .wpcf7-submit{
            @include submit-as-h5;
            @include submit-as-h5-purple;
            &:focus{
                -webkit-box-shadow: none;
                        box-shadow: none;
            }
        }
        .region{
            margin-bottom: -4px;
            label{
                font-weight: 300;
                font-size: 19px;
                vertical-align: top;
                margin-top: 16px;
                line-height: 22px;
            }
            input[type="checkbox"] {
                display:none;
            }
            input[type="checkbox"] + span {
                display:inline-block;
                width:20px;
                height:20px;
                margin: -4px 4px 0 0;
                vertical-align:middle;
                background:url(../img/icon-set.png) -28px -103px no-repeat;
                cursor:pointer;
                margin-right: 50px;
                padding-left: 22px;
            }
            input[type="checkbox"]:checked + span {
                background:url(../img/icon-set.png) -4px -103px no-repeat;
            }
            .wpcf7-list-item{
                margin: 0;
                vertical-align: top;
                margin-right: 44px;
            }
            .wpcf7-list-item.first:after{
                content: url(/wp-content/themes/understrap/img/region-latvia-small.png);
                position: relative;
                top: 6px;
                left: 15px;
            }
            .wpcf7-list-item:after{
                content: url(/wp-content/themes/understrap/img/region-baltics-small.png);
                position: relative;
                top: -12px;
                left: 13px;
            }
            .wpcf7-list-item.last:after{
                content: url(/wp-content/themes/understrap/img/region-world-small.png);
                position: relative;
                top: -11px;
                left: 27px;
            }
        }
        .comment{
            width: 100%;
            textarea{
                height: 92px;
                resize: none;
            }
        }
        .wpcf7-submit{
            width: auto;
            float: right;
            margin-top: 30px;
            margin-bottom: 25px;
        }
    }
}

html[lang = "ru-RU"]{
    #contact-box .wpcf7-form .region {
        .wpcf7-list-item.first:after{
            left: 26px;
        }
        .wpcf7-list-item:after{
            left: 28px;
        }
        .wpcf7-list-item.last:after{
            left: 5px;
        }
    }
}

@media only screen and (max-width: 747px){
    #contact-box{
        .wpcf7-form{
            .phone-nr,
            .your-email,
            .company,
            .your-name{
                max-width: 100%;
                width: 100%;
            }

            .your-email,
            .your-name{
                margin-right: 0px;
            }
            input{
                width: 100%;
            }
            label{
                width: 100%;
            }
            .region{
                .wpcf7-list-item{
                    min-height: 85px;
                }
                label{
                    width: auto;
                }
            }
        }
    }
}

.page-template-page-vacancy{
    .wrapper{
        padding-top: 54px;
        padding-bottom: 59px;
    }
}

.page-template-page-calculator{
    .wrapper {
        padding: 51px 0;
    }
    .attachment-post-thumbnail{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    }

    #wrapper-footer-full{
        display: none
    }

    #wrapper-footer-calc{
        background-color: #e0cfe5;
    }
}

#wrapper-footer-calc-2,
#wrapper-footer-calc{
    position: relative;
    min-height: 218px;
    background-color: #EDECEC;
    padding: 51px 0;
    @include btn-purple;

    .widget-title {
        margin-bottom: 15px;
    }

    .dec-img-1{
        position: absolute;
        width: 270px;
        height: 235px;
        left: 31px;
        top: 56px;
    }

    .dec-img-2{
        position: absolute;
        width: 286px;
        height: 259px;
        right: 29px;
        top: -147px;
    }
}

@media only screen and (max-width: 1200px) {
    #wrapper-footer-calc .dec-img-2{
        width: 209px;
        height: 200px;
        left: auto;
        right: 31px;
        top: -109px;
    }
}

@media only screen and (max-width: 1870px) {
    #wrapper-footer-calc-2 .dec-img-1{
        display: block;
        left: auto;
        right: 7px;
        top: 32px;
    }
}

@media only screen and (max-width: 1200px) {
    #wrapper-footer-calc-2 .dec-img-1{
        display: block;
        width: 209px;
        height: 200px;
        left: auto;
        right: 5px;
        top: -109px;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    #wrapper-footer-calc .dec-img-2{
        width: 121px;
        height: 200px;
        left: auto;
        right: 7px;
        top: -63px;
    }
    #wrapper-footer-calc-2 .dec-img-1{
        width: 121px;
        height: 200px;
        left: auto;
        right: 7px;
        top: -63px;
    }
    #wrapper-footer-full{
        .widget-title {
            padding-right: 92px;
        }
    }

}

@media only screen and (max-width: 1680px) {
    #wrapper-footer-calc .dec-img-2{
        display: block;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px){

}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .page-template-page-calculator{
        .wrapper {
            padding-top: 33px;
        }
    }
}

.ps_calc_galvenais{
    width: 100% !important;
    display: inline-block;
    margin-bottom: 4px;
    .ps_calc_galvenais_body{
        @include custom-radio-btn;
        @include custom-checkbox;
        #ps_calc_galvenais_submit{
            @include submit-as-h5;
            margin-top: 0;
        }
        .ps_calc_galvenais_row {
            border-bottom: 1px dashed #757578;
            margin-bottom: 5px;
            padding: 9px 0 8px 0;
            position: relative;
        }
        .ps_calc_region_row{
            padding-bottom: 16px;
            label{
                margin: 0;
            }
        }
        .ps_calc_baltija_row,
        .ps_calc_shipping_type_lietuva,
        .ps_calc_liguma_klients_row{
            h3{
                display: inline-block;
                margin-right: 7px;
            }
            label{
                margin: 0 15px;
            }
        }
        .ps_calc_shipping_type_row{
            h3{
                display: inline-block;
                margin-right: 28px;
            }
            label{
                margin: 0 14px;
            }
        }
        .ps_calc_postindex_row{
            padding: 7px 0px 13px 0px;
            &:after {
              content: "";
              display: table;
              clear: both;
            }
            label{
                font-weight: bold;
                display: inline-block;
                margin: 0;
            }
            input{
                width: 100%;
                padding-left: 47px;
            }
            .ps_calc_postindex_col{
                display: block;
                width: 50%;
                float: left;
                position: relative;
            }
            .ps_calc_postindex_col.col1{
                padding-right: 14px;
                .ps_calc_input_info{
                    position: absolute;
                    top: 35px;
                    left: 15px;
                }
            }
            .ps_calc_postindex_col.col2{
                padding-left: 14px;
                .ps_calc_input_info{
                    position: absolute;
                    top: 35px;
                    left: 28px;
                }
            }
        }
        .ps_calc_galvenais_pecapmaksa,
        .ps_calc_galvenais_apdrosinasana,
        .ps_calc_galvenais_svars{
            padding: 13px 1px 18px 1px;
            b{
                margin-right: 24px;
            }
            input{
                max-width: 106px;
            }
        }
        .ps_calc_extra_services{
            padding: 8px 1px 15px 1px;
            &:after {
              content: "";
              display: table;
              clear: both;
            }
            label{
                margin: 0;
                line-height: 27px;
            }

            input:disabled+label {
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
              filter: alpha(opacity=50);
              -moz-opacity: 0.5;
              -khtml-opacity: 0.5;
              opacity: 0.5;
            }


            .ps_calc_extra_services_col{
                display: block;
                width: 50%;
                float: left;
            }
            .ps_calc_extra_services_col:first-of-type{
                padding-right: 14px;
            }
            .ps_calc_extra_services_col:last-of-type{
                padding-left: 14px;
            }
        }
        .ps_calc_result_row{
            &:after {
              content: "";
              display: table;
              clear: both;
            }
            label{
                margin: 0;
                pointer-events: none;
                display: block;
            }
            input,
            h4{
                font-size: 19px;
                line-height: 22.8px;
                font-weight: 300;
                color: #FF6C36;
            }
            input{
                border: 0;
                margin: 0;
                padding: 0;
                pointer-events: none;
                max-width: 153px;
                height: auto;
            }
            .ps_calc_result_col{
                display: block;
                width: 50%;
                float: left;
                textarea{
                    display: inline-block;
                    resize: none;
                    pointer-events: none;
                }
                h4{
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 4px;
                }
                #ps_calc_deliverytime{
                    max-width: 160px;
                    margin-left: 0px;
                }
            }
            .ps_calc_result_col:first-of-type{
                padding-right: 14px;
            }
            .ps_calc_result_col:last-of-type{
                padding-left: 14px;
            }
        }
        .ps_calc_countries_fromto_row{
            &:after {
              content: "";
              display: table;
              clear: both;
            }
            label{
                font-weight: bold;
                display: inline-block;
                margin: 0;
            }
            .select2{
                width: 100% !important;
            }
            .ps_calc_countries_fromto_col{
                display: block;
                width: 50%;
                float: left;
            }
            .ps_calc_countries_fromto_col:first-of-type{
                padding-right: 14px;
            }
            .ps_calc_countries_fromto_col:last-of-type{
                padding-left: 14px;
            }
        }

        .ps_calc_igaunija_to{
            padding: 8px 1px 15px 1px;
            &:after {
              content: "";
              display: table;
              clear: both;
            }
            label{
                font-weight: bold;
                display: inline-block;
                margin: 0;
            }
            input{
                width: 100%;
            }
            .select2{
                width: 100% !important;
            }
            .ps_calc_igaunija_to_col{
                display: block;
                width: 50%;
                float: left;
            }
        }

        label[for=ps_calc_country_lv]:after{
            content: "";
            display: inline-block;
            background: url(/wp-content/themes/understrap/img/region-latvia-small.png) no-repeat;
            width: 82px;
            height: 48px;
            vertical-align: middle;
            margin: 0px 20px 0px 10px;
        }
        label[for=ps_calc_country_balt]:after{
            content: "";
            display: inline-block;
            background: url(/wp-content/themes/understrap/img/region-baltics-small.png) no-repeat;
            width: 82px;
            height: 84px;
            vertical-align: middle;
            margin: 0 2px 0 9px;
        }
        label[for=ps_calc_country_pas]:after{
            content: "";
            display: inline-block;
            background: url(/wp-content/themes/understrap/img/region-world-small.png) no-repeat;
            width: 81px;
            height: 82px;
            vertical-align: middle;
            margin: 0 9px 0 9px;
        }
        label,
        a,
        b{
            letter-spacing: 0.4px;
        }
        .ps_calc_shipping_type_row,
        .ps_calc_galvenais_svars,
        .ps_calc_galvenais_apdrosinasana,
        .ps_calc_galvenais_pecapmaksa{
            padding-right: 32px !important;
        }
        .ps_calc_error{
            color: #f36c3c;
        }
        .input-wrap{
            display: inline-block;
            vertical-align: middle;
        }
    }
    .search-index-link{

    }
    .search-index-iframe{
        display: none;
        iframe{
            width: 100%;
            border: 0;
            height: 487px;
            /*border: 1px dashed gray;*/
        }
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px){
    .ps_calc_galvenais{
        .ps_calc_header{
            h1{
                letter-spacing: 0.3px;
                margin-bottom: 12px;
            }
        }
        .ps_calc_galvenais_body{
            label[for=ps_calc_country_lv]:after{
                height: 48px;
                margin: 0px 20px 0px 15px;
            }
            label[for=ps_calc_country_balt]:after{
                margin: 0 2px 0 15px;
            }
            label[for=ps_calc_country_pas]:after{
                margin: 0 9px 0 16px;
            }
            .ps_calc_galvenais_row{
                padding: 10px 0 10px 0;
                .ps_calc_liguma_klients_row{
                    h3{
                        margin-right: 14px;
                    }
                    label{
                        margin: 0 10px;
                    }
                }
            }
            .ps_calc_shipping_type_row{
                h3{
                    display: inline-block;
                    margin-right: 5px;
                }
            }
            .ps_calc_result_row{
                padding: 6px 0 5px 0;
                h4 {
                    font-size: 17px;
                }
            }
        }
    }
}
/*Mobile*/
@media only screen and (max-width: 767px) {
    .ps_calc_galvenais{
        .search-index-iframe{
            iframe{
                height: 750px;
            }
        }
        .ps_calc_galvenais_body{
            .ps_calc_region_row{
                padding-bottom: 15px;
                label{
                    position: relative;
                    margin-top: 75px;
                    font-size: 17px;
                    margin-right: 24px;
                }
                label:last-of-type{
                    margin-right: 0px;
                }
                label[for=ps_calc_country_lv]:after{
                    position: absolute;
                    top: -61px;
                    left: -13px;
                    background-size: 65px;
                }
                label[for=ps_calc_country_balt]:after{
                    position: absolute;
                    top: -76px;
                    left: -5px;
                    background-size: 50px;
                }
                label[for=ps_calc_country_pas]:after{
                    position: absolute;
                    top: -76px;
                    left: -3px;
                    background-size: 65px;
                }
            }
            .ps_calc_liguma_klients_row{
                h3{
                    display: inline-block;
                    margin-right: 8px;
                }
                label{
                    margin: 0 10px;
                }
            }
            .ps_calc_shipping_type_row,
            .ps_calc_shipping_type_lietuva{
                padding-bottom: 17px;
                h3 {
                    display: block;
                }
                label{
                    margin: 0;
                    margin-right: 17px;
                }
                .ps_calc_info_tooltip {
                    top: 27%;
                }
            }
            .ps_calc_postindex_row {
                padding-top: 7px;
                .ps_calc_postindex_col{
                    width: 100%;
                    float: none;
                    padding: 0 !important;
                    margin-bottom: 16px;
                }
                .ps_calc_postindex_col.col2{
                    .ps_calc_input_info{
                        left: 15px;
                    }
                }
            }
            .ps_calc_extra_services{
                .ps_calc_extra_services_col {
                    float: none;
                    width: 100%;
                    padding: 0 !important;
                }
            }
            .ps_calc_result_row{
                .ps_calc_result_col{
                    width: 100%;
                    float: none;
                    padding: 0 !important;
                    &:first-of-type{
                        padding-bottom: 22px;
                    }
                }
                input,
                h4{
                    font-size: 17px;
                }
            }
            .ps_calc_info_tooltip{
                .ps_calc_info_tooltip_content{
                    left: unset;
                    right: 30px;
                }
            }
        }
    }
}

.ps_calc_info_tooltip{
    position: absolute;
    background-image: url(../img/icon-set.png);
    background-repeat: no-repeat;
    background-position: -26px -128px;
    width: 22px;
    height: 21px;
    right: 5px;
    top: 50%;
    z-index: 10;
    -webkit-transform: translate( 0, -50%);
    -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
    &:hover{
        background-position: -26px -152px;
    }
    .ps_calc_info_tooltip_content{
        border: 1px solid #939598;
        background-color: #fff;
        position: absolute;
        top: 5px;
        left: 41px;
        display: none;
        padding: 10px;
        img{
            max-width: none;
        }
    }
    &:hover .ps_calc_info_tooltip_content{
        display: block;
    }
}

.ps_calc_debug {
    position: fixed;
    bottom: 0;
    right: 0;
    border: 1px solid #000;
    background: #fff;
    z-index: 100;
    max-height: 350px;
    width: 25%;
    overflow: auto;
}

.ps_calc_input_error{
    background-color: #FFF0EA !important;
    border-color: #F36C3C !important;
    -webkit-box-shadow: 0px 0px 0px 1px #F36C3C !important;
    box-shadow: 0px 0px 0px 1px #F36C3C !important;
}

#ps_calc_galvenais_submit:disabled {
    background-color: #acdcde !important;
    cursor: not-allowed !important;
}


.shipping-check{
    background-color: #EDECEC;
}

/*Tablet*/
@media only screen and (max-width: 1280px){
    .shipping-check{
        padding-bottom: 40px;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .shipping-check{
        padding: 0;
        padding-bottom: 39px;
    }
}

.ps_calc_ems{
    #calc_ems_submit{
        @include submit-as-h5;
        display: inline-block;
        vertical-align: top;
        margin-top: 25px;
    }
    .ps_calc_header{
        display: inline-block;
        vertical-align: top;
        margin-top: 23px;
        margin-bottom: 19px;
        width: 22%;
        h1{
            margin-bottom: 1px;
        }
    }
    .ps_calc_header{
        display: inline-block;
        vertical-align: top;
        margin-top: 23px;
        margin-bottom: 20px;
        margin-right: 9px;
        h1{
            margin-bottom: 1px;
            letter-spacing: 0.4px;
        }
    }
    .ps_calc_ems_body{
        display: inline-block;
        vertical-align: top;
        margin-top: 13px;
        width: 70%;
        .ps_calc_ems_col{
            display: block;
            width: 36.6%;
            float: left;
            position: relative;
            padding-right: 30px;
            .ps_calc_input_info{
                position: absolute;
                top: 35px;
                left: 16px;
            }
        }
        label{
            font-weight: bold;
            display: inline-block;
            margin: 0;
        }
        .select2{
            width: 100% !important;
            height: 46px;
        }
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px){
    .ps_calc_ems{
        .ps_calc_header{
            margin-top: 32px;
            margin-bottom: 0;
            width: 100%;
            h1{
                margin-bottom: 5px;
            }
        }
        .ps_calc_ems_body{
            margin-top: 6px;
            width: 100%;
            .ps_calc_ems_col{
                width: 44.2%;
                padding-left: 0px;
                padding-right: 29px;
            }
            .select2-selection{
                font-size: 14px;
            }
        }
        #calc_ems_submit{
            margin-top: 19px;
            margin-left: 0px;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .ps_calc_ems{
        .ps_calc_header{
            width: 100%;
            margin-top: 34px;
            margin-bottom: 0;
            h1{
                margin-bottom: 5px;
            }
        }
        .ps_calc_ems_body{
            margin-top: -2px;
            width: 100%;
            .ps_calc_ems_col{
                width: 100%;
                padding-left: 0px;
                padding-right: 0px;
                margin-bottom: 12px;
            }
        }
        #calc_ems_submit{
            margin-top: 8px;
            margin-left: 0px;
            width: 100%;
        }
    }

}

/*Tablet*/
@media only screen and (max-width: 1280px){
    .measure-compliance{
        padding-bottom: 40px;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .measure-compliance{
        padding: 0;
        padding-bottom: 39px;
    }
}

.calc_izmera_atbilstiba{
    .ps_calc_header{
        display: inline-block;
        vertical-align: top;
        margin-top: 18px;
        margin-bottom: 2px;
        margin-right: 122px;
    }
    .ps_calc_izmera_atbilstiba_body{
        display: inline-block;
        vertical-align: top;
        margin-top: 13px;
        width: 70%;
        label{
            display: block;
            font-weight: bold;
            letter-spacing: 0.4px;
            margin-bottom: 4px;
            position: relative;
        }
        span{
            font-size: 13px;
            font-weight: bold;
            margin-left: 10px;
            margin-right: 10px;
        }
        input{
            width: 27.1%;
            display: inline-block;
        }
        .ps_calc_izmera_atbilstiba_package_size {
            display: inline-block;
            width: 44.8%;
            margin-right: 17px;
        }
        .ps_calc_izmera_atbilstiba_package_weight {
            display: inline-block;
            width: 13.2%;
            input{
                width: 93%;
            }
        }
    }
    #calc_izmera_atbilstiba_submit{
        @include submit-as-h5;
        display: inline-block;
        vertical-align: top;
        margin-top: 27px;
        margin-left: 0px;
    }
}

.calc_izmera_atbilstiba_lt{
    .ps_calc_izmera_atbilstiba_body{
        margin-top: 36px;
    }
    .ps_calc_header {
        margin-right: 60px;
        max-width: 290px;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px){
    .calc_izmera_atbilstiba{
        .ps_calc_header{
            margin-top: 31px;
            margin-bottom: 15px;
            h1{
                margin-bottom: 0;
            }
        }
        .ps_calc_izmera_atbilstiba_body{
            display: inline-block;
            vertical-align: top;
            margin-top: 0px;
            width: 100%;
            label{
                margin-bottom: 1px;
            }
            span{
                margin-left: 9px;
                margin-right: 8px;
            }
            input{
                width: 29.3%;
                display: inline-block;
                height: 46px;
                font-size: 14px;
            }
            .ps_calc_izmera_atbilstiba_package_size {
                display: inline-block;
                width: 56.4%;
                margin-right: 3px;
                .ps_calc_info_tooltip{
                    right: 20px;
                }
            }
            .ps_calc_izmera_atbilstiba_package_weight {
                display: inline-block;
                width: 16%;
            }
        }
        #calc_izmera_atbilstiba_submit{
            margin-top: 20px;
            margin-left: 0;
            vertical-align: bottom;
        }
    }
    .calc_izmera_atbilstiba_lt{
        .ps_calc_header {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
        .ps_calc_izmera_atbilstiba_body{
            margin-top: 13px;
        }
    }
}

@media only screen and (max-width: 1199px){
    .calc_izmera_atbilstiba{
        .ps_calc_izmera_atbilstiba_body{
            input{
                width: 28.3%;
            }
        }
    }
}

@media only screen and (max-width: 991px){
    .calc_izmera_atbilstiba{
        .ps_calc_izmera_atbilstiba_body{
            input{
                width: 26.8%;
            }
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .calc_izmera_atbilstiba{
        .ps_calc_header{
            margin-top: 33px;
            margin-bottom: -2px;
        }
        .ps_calc_izmera_atbilstiba_body{
            display: inline-block;
            vertical-align: top;
            margin-top: 0px;
            width: 100%;
            label{
                margin-bottom: 1px;
            }
            span{
                margin-left: 9px;
                margin-right: 8px;
            }
            input{
                width: 26.8%;
                display: inline-block;
                height: 46px;
                font-size: 14px;
            }
            .ps_calc_izmera_atbilstiba_package_size {
                display: inline-block;
                width: 100%;
                margin-right: 17px;
                .ps_calc_info_tooltip{
                    right: -3px;
                    .ps_calc_info_tooltip_content {
                        top: 5px;
                        left: auto;
                        right: 30px;
                    }
                }
            }
            .ps_calc_izmera_atbilstiba_package_weight {
                display: inline-block;
                width: 37%;
                margin-top: 13px;
            }
        }
        #calc_izmera_atbilstiba_submit{
            margin-top: 20px;
            margin-left: 0;
            width: 100%;
        }
    }
}

.sending-check{
    background-color: #EDECEC;
}


/*Tablet*/
@media only screen and (max-width: 1280px){
    .sending-check{
        padding-bottom: 40px;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .sending-check{
        padding: 0;
        padding-bottom: 39px;
    }
}

.ps_calc_x2_pieejamiba{
    .ps_calc_header{
        display: inline-block;
        vertical-align: top;
        margin-top: 23px;
        margin-bottom: 20px;
        margin-right: 35px;
        h1{
            margin-bottom: 1px;
            letter-spacing: 0.4px;
        }
    }
    .ps_calc_x2_pieejamiba_body{
        display: inline-block;
        vertical-align: top;
        margin-top: 13px;
        width: 70%;
        .ps_calc_pieejamiba_col{
            display: block;
            width: 36.6%;
            float: left;
            position: relative;
            padding-left: 2px;
            padding-right: 28px;
            .ps_calc_input_info{
                position: absolute;
                top: 35px;
                left: 16px;
            }
        }
        label{
            font-weight: bold;
            display: inline-block;
            margin: 0;
        }
        input{
            width: 100%;
            padding-left: 47px;
            height: 46px;
        }
    }
    #calc_x1_pieejamiba_submit,
    #calc_x2_pieejamiba_submit{
        @include submit-as-h5;
        display: inline-block;
        vertical-align: top;
        margin-top: 25px;
        margin-left: 3px;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px){
    .ps_calc_x2_pieejamiba{
        .ps_calc_header{
            margin-top: 32px;
            margin-bottom: 0;
            h1{
                margin-bottom: 5px;
            }
        }
        .ps_calc_x2_pieejamiba_body{
            margin-top: 6px;
            width: 100%;
            .ps_calc_pieejamiba_col{
                width: 44.2%;
                padding-left: 0px;
                padding-right: 29px;
            }
        }
        #calc_x2_pieejamiba_submit{
            margin-top: 20px;
            margin-left: 0px;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .ps_calc_x2_pieejamiba{
        .ps_calc_header{
            margin-top: 34px;
            margin-bottom: 0;
            h1{
                margin-bottom: 5px;
            }
        }
        .ps_calc_x2_pieejamiba_body{
            margin-top: -2px;
            width: 100%;
            .ps_calc_pieejamiba_col{
                width: 100%;
                padding-left: 0px;
                padding-right: 0px;
                margin-bottom: 12px;
            }
        }
        #calc_x2_pieejamiba_submit{
            margin-top: 8px;
            margin-left: 0px;
            width: 100%;
        }
    }
}

.ps_calc_map_container{
    width: 100%;
    height: 781px;
    margin-top: 33px;
}

/*Tablet*/
@media only screen and (max-width: 1280px){
    .ps_calc_map_container{
        margin-top: 24px;
        height: 447px;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .ps_calc_map_container{
        margin-top: 24px;
        height: 181px;
    }
}

.ps_calc_marker_pagination{
    text-align: center;
    a{
        margin: 0 6px;
        &:hover,
        &:active,
        &.active{
            color: #00B0B9;
        }
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px){
    .ps_calc_marker_pagination{
        margin-top: 30px;
        a{
            padding: 7px 10px;
        }
    }
}

.ps_calc_marker_container{
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 20px;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    .ps_calc_map_openbox{
        border-radius: 3px;
        background-color: #edecec;
        width: 47.6%;
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        margin: 7px 15px;
        padding: 8px 15px;
        &:hover{
            background-color: #c9c8c9;
        }
    }
    h4{
        letter-spacing: 0.2px;
        margin-bottom: -1px;
    }
    p{
        margin-bottom: -2px;
    }
}


/*Tablet*/
@media only screen and (max-width: 1280px){
    .ps_calc_marker_container{
        .ps_calc_map_openbox{
            width: 46.8%;
        }
    }
}

@media only screen and (max-width: 991px){
    .ps_calc_marker_container{
        margin-left: 0;
        margin-right: 0;
        margin-bottom: -34px;
        .ps_calc_map_openbox{
            width: 100%;
            margin: 15px 0px;
            padding: 11px 15px;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .ps_calc_marker_container{
        margin-bottom: -12px;
        .ps_calc_map_openbox{
            padding: 13px 15px;
        }
    }
}

.ps_calc_map_infobox{
    p{
        margin: 0;
    }
}

.ps_calc_filter_container{
    margin-top: 24px;
    clear: both;
    label{
        font-weight: 700;
        margin-bottom: 0px;
        letter-spacing: 0.3px;
    }
    input,
    .select2{
        width: 286px !important;
        margin-top: -2px;
        margin-right: 30px;
        height: 46px;
    }
    #ps_calc_filter{
        @include submit-as-h5;
        margin-top: 24px;
        margin-left: 5px;
    }
    .ps_calc_map_col{
        display: block;
        width: 25.5%;
        float: left;
        position: relative;
        padding-left: 2px;
        padding-right: 28px;
    }
}


@media only screen and (max-width: 1280px){
    .ps_calc_filter_container{
        margin-top: 17px;
        input,
        .select2{
            width: 100% !important;
        }
        #ps_calc_filter{
            margin-top: 20px;
            margin-left: 0px;
        }
        .ps_calc_map_col{
            width: 32%;
        }
    }
}

/*Tablet*/
@media only screen and (max-width: 991px){
    .ps_calc_filter_container{
        margin-top: 17px;
        input,
        .select2{
            width: 100% !important;
        }
        #ps_calc_filter{
            margin-top: 20px;
            margin-left: 0px;
        }
        .ps_calc_map_col{
            width: 44%;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .ps_calc_filter_container{
        margin-top: 24px;
        input,
        .select2{
            width: 100% !important;
        }
        #ps_calc_filter{
            width: 100%;
            margin-top: 7px;
            margin-left: 0px;
        }
        .ps_calc_map_col{
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
            margin-bottom: 15px;
        }
    }
}

.special_results {
    font-size: 14px;
    color: #00b0b9;
    line-height: normal;
}

@media (max-width: 768px){
   .special_results {
       position: initial;
   }
}

.page-template-page-services{
    .wrapper{
        padding-bottom: 56px;
        padding-top: 51px;
    }
    .region-image {
        min-height: 267px;
        position: relative;
        margin-bottom: 18px;
        img{
            position: absolute;
            left: 0;
            top: 50%;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
                transform: translate(0, -50%);
        }
    }
    .region-description{
        letter-spacing: 0.4px;
        h4{
            letter-spacing: 0.2px;
        }
    }
    .page-title{
        letter-spacing: 0.2px;
    }
    .page-list{
        margin-top: 27px;
        ul{
            padding: 0;
            margin: 0;
            li{
                list-style: none;
                border-top: 1px dashed #757578;
                max-width: 285px;
                position: relative;
                @include arrow(-45deg){
                    right: 14px;
                    top: 17px;
                };
                &:hover,
                &:focus{
                    &:after{
                        border-color:  $tc_mint;
                    }
                }
                a{
                    &:focus{
                        outline: 0;
                    }
                    padding: 8px 0;
                    display: block;
                    width: 100%;
                    padding-right: 30px;
                    padding-left: 10px;
                    letter-spacing: 0.4px;
                    &:hover,
                    &:focus{
                        color: $tc_mint;
                    }
                }
            }
            li:last-of-type{
                border-bottom: 1px dashed #757578;
            }
        }
    }
    .service-info-section{
        margin-top: 50px;
        .col-12{
            margin-bottom: 10px;
        }
    }
}

/*Tablet*/
@media only screen and (max-width: 991px) and (min-width: 767px) {
    .page-template-page-services{
        .wrapper{
            padding-top: 34px;
            padding-bottom: 11px;
        }
        .region-menu{
            .col-12:first-child{
                padding-left: 15px;
                padding-right: 15px;
                img{
                    max-width: 131px;
                }
            }
            .col-12{
                padding-left: 22px;
                padding-right: 10px;
                margin-bottom: 29px;
                img{
                    max-width: 100px;
                }
            }
            .col-12:last-child{
                padding-left: 15px;
                padding-right: 15px;
                img{
                    max-width: 128px;
                }
                .region-image{
                    margin-bottom: 5px;
                }
                .page-list{
                    margin-top: 16px;
                }
            }
            .col-md-6:first-child{
                -webkit-flex: 0 0 41.66667%;
                -ms-flex: 0 0 41.66667%;
                flex: 0 0 41.66667%;
                max-width: 41.66667%;
            }
        }
        .region-image {
            margin-bottom: 9px;
            min-height: 156px;
        }
        .page-list{
            margin-top: 27px;
            ul{
                li{
                    @include arrow(-45deg){
                        right: 12px;
                        top: 14px;
                    };
                    a{
                        padding: 7px 0;
                    }
                }
            }
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .page-template-page-services{
        .wrapper{
            padding-top: 33px;
            padding-bottom: 11px;
        }
        .region-menu{
            .col-12:first-child{
                margin-bottom: 63px;
                img{
                    max-width: 131px;
                }
                .region-description{
                    margin-top: 0px;
                }
                .page-list{
                    margin-top: 27px;
                }
            }
            .col-12{
                margin-bottom: 56px;
                img{
                    max-width: 100px;
                }
                .region-description{
                    margin-top: 37px;
                }
                .page-list{
                    margin-top: 17px;
                }
            }
            .col-12:last-child{
                margin-bottom: 28px;
                img{
                    max-width: 128px;
                }
                .region-image{
                    margin-bottom: 5px;
                }
                .page-list{
                    margin-top: 16px;
                }
                .region-description{
                    margin-top: 36px;
                }
            }
        }
        .region-image {
            margin-bottom: 20px;
            min-height: 99px;
        }
        .page-list{
            margin-top: 27px;
            ul{
                li{
                    @include arrow(-45deg){
                        right: 12px;
                        top: 14px;
                    };
                    a{
                        padding: 7px 0;
                    }
                }
            }
        }
    }
}


.category-menu{
    float: right;
    margin-top: 4px;
    ul{
        margin: 0;
        padding: 0;
        li{
            display: inline-block;
            margin-left: 24px;
            a{
                line-height: 27.6px;
                font-weight: 300;
                font-size: 23px;
                color: #A6A6A8;
            }

            &.current-menu-ancestor > a{
                position: relative;
                @include arrow(45deg){
                    -webkit-transform: rotate(45deg)  translate(0, -50%);
                    -ms-transform: rotate(45deg)  translate(0, -50%);
                    transform: rotate(45deg) translate(0, -50%);
                    right: 50%;
                    bottom: -10px;
                };
            }

            &.current-menu-parent a,
            &.current-menu-ancestor a,
            &.current-menu-item a{
                color: #282828;
            }

            &.current-menu-parent ul,
            &.current-menu-ancestor ul,
            &.current-menu-item ul{
                display: block;
                position: absolute;
                right: 0;
                text-align: right;
            }
        }
        ul{
            display: none;
            margin-top: 19px;
            margin-right: 16px;
            a{
                font-weight: 300;
                font-size: 16px;
                color: #282828;
                letter-spacing: 0.4px;
            }
            li{
                &.current-menu-parent a,
                &.current-menu-ancestor a,
                &.current-menu-item a{
                    color: #00B0B9;
                }
            }
        }
    }
}

.category-menu-mobile{
    display: none;
}
/*Tablet*/
@media only screen and (max-width: 1280px) {
    .category-menu{
        margin-top: -2px;
        margin-right: 7px;
        .sub-menu{
            display: none !important;
        }
        ul{
            li{
                margin-left: 32px;
                a{
                    font-size: 20px;
                }
            }
        }
    }
    .category-menu-mobile{
        display: block;
        float: right;
        margin-top: 16px;
        margin-right: 7px;
        max-width: 286px;
        width: 100%;
        .category-menu{
            display: none;
        }
        .select2{
            width: 100% !important;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .category-menu{
        display: none;
    }
    .category-menu-mobile{
        display: block;
        margin-right: 0;
        margin-top: 0;
        max-width: 100%;
        .select2{
            width: 100% !important;
        }
        .category-menu{
            display: block;
            float: none;
            margin-top: 8px;
            margin-bottom: 27px;
            margin-right: 8px;
            ul{
                li{
                    margin-left: 0px;
                    margin-right: 32px;
                    a{
                        font-weight: 300;
                        font-size: 20px;
                    }

                    &.current-menu-ancestor > a{
                        @include arrow(45deg){
                            -webkit-transform: rotate(45deg)  translate(0, -50%);
                            -ms-transform: rotate(45deg)  translate(0, -50%);
                            transform: rotate(45deg) translate(0, -50%);
                            right: 50%;
                            bottom: -11px;
                        };
                    }

                    &.current-menu-parent a,
                    &.current-menu-ancestor a,
                    &.current-menu-item a{
                        color: #282828;
                    }

                    &.current-menu-parent ul,
                    &.current-menu-ancestor ul,
                    &.current-menu-item ul{
                        display: block;
                        position: absolute;
                        right: 0;
                        text-align: right;
                    }
                }
            }
        }
    }
}

.service-page{
    padding-top: 50px;
    padding-bottom: 0px;
    .post-thumbnail {
        max-height: 55px;
        position: relative;
        display: inline-block;
        right: -1px;
        top: -16px;
    }
    .title{
        position: relative;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    .service-page{
        padding-top: 39px;
        .post-thumbnail{
            right: 8px;
            top: -22px;
            margin-bottom: -22px;
        }
        P{
            line-height: 20px;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .service-page{
        padding-top: 32px;
        .post-thumbnail{
            right: 0px;
            top: 4px;
            margin-left: 15px;
            margin-bottom: 0px;
        }
        .content{
            text-align: justify;
        }
    }
}

.service-header{
    border-bottom: 1px dashed #282828;
    margin-bottom: 34px;
    .page-title{
        letter-spacing: 0.4px;
        display: block;
        margin-bottom: -1px;
    }
    .category-title{
        letter-spacing: 0.4px;
        display: inline-block;
        margin-bottom: 12px;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    .service-header{
        min-height: 121px;
        margin-bottom: 42px;
        .page-title{
            margin-bottom: 5px;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .service-header{
        padding-bottom: 19px;
        padding-bottom: 128px;
        margin-bottom: 16px;
        .category-title{
            display: none;
        }
    }
}


.search-index{
    background-color: #B2E8EA;
    padding-top: 50px;
    padding-bottom: 35px;
    .search-index-iframe{
        iframe{
            width: 100%;
            border: 0;
            height: 457px;
            /*border: 1px dashed gray;*/
        }
    }
    h1{
        letter-spacing: 0.4px;
        margin-bottom: 20px;
    }
    .dec-img{
        position: absolute;
        top: 44px;
        right: 155px;
    }
    .col{
        position: relative;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px) {
    .search-index{
        padding-top: 34px;
        h1{
            margin-bottom: 18px;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .search-index{
        background-color: #B2E8EA;
        padding-top: 32px;
        padding-bottom: 35px;
        .container{
            padding-right: 21px;
            padding-left: 21px;
        }
        .search-index-iframe{
            iframe{
                height: 750px;
            }
        }
        h1{
            margin-bottom: 15px;
        }
    }
}

.footer-contact-form{
    background-color: #b2e8ea;
    padding: 52px 0 58px 0px;
    .contact-form-thumbnail{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    }
    .wpcf7 .wpcf7-form{
        .wpcf7-form-control-wrap{
            display: inline-block;
        }
        .tema,
        .phone-nr,
        .your-email,
        .your-name{
            max-width: 285px;
        }

        .tema{
            width: 100%;
        }

        .your-email,
        .your-name{
            margin-right: 25px;
        }

        .select2{
            width: 100% !important;
        }

        label{
            width: auto;
            display: inline-block;
            font-weight: bold;
            letter-spacing: 0.4px;
            margin-bottom: 13px;
        }
        textarea{
            border-color: #58595B;
        }
        input{
            height: 47px;
            border-color: #58595B;
        }
        .wpcf7-submit{
            @include submit-as-h5;
        }
        .comment{
            width: 100%;
            textarea{
                height: 92px;
                resize: none;
            }
        }
        .wpcf7-submit{
            width: auto;
            float: right;
            /*margin-top: 30px;*/
            margin-top: 16px;
        }
    }
}

/*Tablet*/
@media only screen and (min-width: 1200px) {
    .footer-contact-form{
        .wpcf7-form{
            min-width: 600px;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .footer-contact-form{
        .wpcf7 .wpcf7-form{
            label{
                width: 100% !important;
                max-width: 100% !important;
                display: block;
                .wpcf7-form-control-wrap{
                    max-width: 100%;
                    margin: 0;
                    width: 100%;
                    .select2 {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.page-template-page-contacts{
    .wrapper{
        padding-top: 53px;
        padding-bottom: 59px;
    }
    .content{
        a {
          text-decoration:none;
          border-bottom: 2px solid $tc_mint;
          -webkit-transition: background 0.1s cubic-bezier(.33,.66,.66,1);
          -o-transition: background 0.1s cubic-bezier(.33,.66,.66,1);
          transition: background 0.1s cubic-bezier(.33,.66,.66,1);
        }

        a:hover {
          background: $tc_mint;
        }
    }
    p{
        margin-bottom: 0;
    }
    h3{
        margin-bottom: 2px;
    }
    h4{
        margin-bottom: 24px;
    }
    .acf-map{
        height: 320px;
        margin-top: 9px;
    }
}

.site-map{
    padding-top: 55px;
    padding-bottom: 80px;
}
.page-template-page-sitemap{
    #wrapper-footer-full{
        display: none;
    }
    .page-list{
        margin-top: 2px;
        & > ul{
            list-style: none;
            padding-left: 16px;
            line-height: 30px;
            letter-spacing: 0.39px;
            margin-bottom: 12px;
            padding-left: 0px;
            & > li{
                margin-bottom: 9px;
                & > a{
                    font-size: 19px;
                    line-height: 22.8px;
                    font-weight: 300;
                }
                & > ul{
                    list-style: none;
                    line-height: 23px;
                    padding-left: 30px;
                    margin-top: -3px;
                    & > li{
                        &::before {
                            content: "•";
                            color: #00B0B9;
                            display: inline-block;
                            width: 0.9em;
                            margin-left: -1em;
                        }
                        &:last-of-type{
                            & > ul{
                                margin-bottom: 12px;
                            }
                        }
                        & > a{

                        }
                        & > ul{
                            list-style: none;
                            padding-left: 26px;
                            line-height: 24px;
                            margin-bottom: 16px;
                            & > li{
                                &::before {
                                    content: "•";
                                    color: #80d9dc;
                                    display: inline-block;
                                    width: 0.9em;
                                    margin-left: -1em;
                                }
                                & > a{

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


/*Tablet*/
@media only screen and (max-width: 1280px){
    .tp-bullets.mint{
        transform: matrix(1, 0, 0, 1, -24, -29) !important;
        width: auto !important;
        .tp-bullet{
            width: 8px !important;
            height: 8px !important;
            display: inline-block;
            position: relative !important;
            left: 0 !important;
            top: 0 !important;
            margin: 0 4px;
        }
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {

}

.tracking-options{
    display: inline-block;
    width: 100%;
    p{
        display: inherit;
    }
    .select2{
        width: 100% !important;
        max-width: 210px;
    }
    ul{
        margin: 0;
        padding: 0;
        column-count: 3;
        li{
            list-style: none;
            a{
                &:hover{
                    color: #F36C3C;
                }
            }
        }
    }
}

.error404{
    overflow-x: hidden;
    height: 100%;
    #page{
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    #error-404-wrapper{
    }
    .page-header {
        text-align: center;
        h1{
            font-size: 200px;
            font-weight: 700;
            line-height: 160px;
        }
        h3{
            text-transform: uppercase;
        }
    }
    .page-content {
        text-align: center;
    }
    //Footer at bottom
    #wrapper-footer-full{
        margin-top: auto;
    }
}

/*Tablet*/
@media only screen and (max-width: 1280px){
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    .error404{
        .page-header {
            h1{
                font-size: 130px;
                font-weight: 700;
                line-height: 108px;
            }
        }
    }
}
.admin-bar #outdated{
    top: 32px;
}
#update_browser{
float: left;
width: 100%;
height:350px;
position: relative;
z-index: 2000;
}
#update_browser_inner{
display: block;
width: 960px;
position: absolute;
left: 50%;
margin-left: -480px;
height: 350px;
}
#btnCloseUpdateBrowser{
display: block;
width: 25px;
height: 25px;
background-image: url(../img/browser_cross.png);
background-repeat: no-repeat;
background-position: center center;
position: absolute;
right: 10px;
top: 15px;
text-decoration: none;
cursor: pointer;
}
#update_browser h1{
width: 100%;
text-align: center;
font-family: Verdana, Geneva, sans-serif;
font-size: 30px;
line-height: 30px;
font-weight: 100;
color: #4d4d4d;
padding: 20px 0px 30px 0px;
}
#update_browser p{
width: 960px;
margin: 0 auto;
text-align: center;
font-family: Verdana, Geneva, sans-serif;
color: #4d4d4d;
font-size: 14px;
line-height: 30px;
padding-bottom: 40px;
}
.browser_link{
float: left;
width: 28%;
height: 140px;
display: block;
font-family: Verdana, Geneva, sans-serif;
font-size: 20px;
color: #4d4d4d;
margin-left: 5%;
text-decoration: none;
cursor:pointer;
}
.browser_link:hover{
color:#d35427
}
.browser_icon{
float: left;
display: block;
width: 100%;
height: 100px;
background-image: url(../img/browser_icons.png);
background-repeat:no-repeat;
background-position:0 0;
}
.browser_name{
float: left;
display: block;
width: 100%;
text-align: center;
line-height: 45px;
}
#opera .browser_icon{
background-position: center 0px !important;
}
#firefox .browser_icon{
background-position: center -100px !important;
}
#chrome .browser_icon{
background-position: center -200px !important;
}

.page-template-page-login{
    #wrapper-footer-full{
        display: none;
    }
    .wrapper{
        padding-top: 37px;
        padding-bottom: 78px;
    }
    .login-form{
        margin: 0 auto;
        width: 286px;
        h3{
            margin-bottom: 12px;
        }
        input{
            width: 100%;
            height: 46px;
        }
        label{
            font-weight: 700;
            margin-bottom: 0px;
        }
        .buttons{
            margin-top: -5px;
            margin-bottom: 5px;
        }
        .submit{
            @include submit-as-h5;
            @include submit-as-h5-orange;
        }
        .register-btn{
            text-align: center;
            margin-bottom: 0;
        }

        .register-btn a{
            border: 0 !important;
        }

        .register-btn a:hover,
        .register-btn a:focus{
            color: #F36C3C;
            background-color: transparent !important;
        }
    }
}

.page-template-default{
    .page-title{
        margin-top: 21px;
    }
}

.dec-img-3,
.dec-img-5,
.dec-img-8{
    display: none;
}

@media only screen and (min-width: 1520px) {
    .dec-img-3{
        display: block;
    }
}

@media only screen and (min-width: 1864px) {
    .dec-img-8{
        display: block;
        position: absolute;
        right: -301px;
        top: -98px;
    }
}

@media only screen and (min-width: 1720px) {
    .dec-img-5{
        position: absolute;
        display: block;
        right: 30px;
        top: 163px;
        width: 210px;
    }
}

@media only screen and (max-width: 1860px) {
    #wrapper-footer-full .dec-img-1{
        display: none;
        width: 209px;
        height: 200px;
        left: auto;
        right: 31px;
        top: -42px;
    }
}

/*Mobile*/
@media only screen and (max-width: 767px) {
    #wrapper-footer-full .dec-img-1{
        display: none;
        width: 121px;
        height: 200px;
        left: auto;
        right: 7px;
        top: -25px;
    }
}

@media only screen and (max-width: 1680px) {
    .dec-img-2{
        display: none;
    }
    #wrapper-footer-full .dec-img-1{
        display: block;
    }
}

/*Tablet*/
@media only screen and (min-width: 1280px){
    .wp-image-263{
        margin-top: -34px;
        max-width: 102%;
        margin-bottom: 39px;
    }
    .wp-image-264{
        margin: 41px 103px 28px 76px;
    }
    .wp-image-265{
        margin: 30px 52px 24px 110px;
    }
}

@media only screen and (min-width: 1280px){
    .home{
        .forcefullwidth_wrapper_tp_banner{
            min-height: 501px;
        }
    }
}

.orange-color{
    background-color: #FF6C36;
    a{
        color: #fff !important;
        &:hover{
            color: #fff !important;
        }
    }
}

/* Style the form - Sūtījuma meklēšana */
.pack-search{
  display: block;
  width: 100%;
  background-color: #FF6C36;
}
/* Style the form - Sūtījuma meklēšana */
.pack-search-form {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}
/* Add some margins for each label */
.pack-search-form label {
  padding: 5px;
  height: auto;
}
.pack-search-form a {
  color: #fff;
  text-decoration:none;
  text-align: left;
}
.pack-search-form h3 {
  margin-right: 8px;
  padding-top: 15px;
}
/* Style the input field - Ievadiet sūtījuma numuru */
.pack-search-form input {
  vertical-align: middle;
  margin: 5px 10px 5px 0;
  padding: 10px;
  min-width: 220px;
  background-color: #fff;
  letter-spacing: 0.4px;
  border-radius: 3px;
  border: 1px solid #ddd;
}
/* Style the submit button - Meklēt */
.pack-search-form .btn-submit {
  padding: 10px 20px;
  background-color: #e05327;
  color: white;
  font-size: 14px;
  line-height: 16.8px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  border-radius: 3px;
  border: 0;
  cursor: pointer;
}
.pack-search-form .btn-submit:hover {
  color: #fff;
  background-color: #FF8C5D;
}
/* Add responsiveness - display the form controls vertically instead of horizontally on screens that are less than 800px wide */
@media (max-width: 800px) {
  .pack-search-form input {
    margin: 10px 0;
  }
  .pack-search-form {
    flex-direction: column;
    align-items: stretch;
  }
  .pack-search-form h3 {
    margin: 0;
  }
}

@media only screen and (max-width: 650px) {

  .ps_calc_galvenais {

    .ps_calc_galvenais_body {

      .ps_calc_shipping_type_row {

          label{
              margin-right: 5px;

              &:last-of-type {
                margin-right: 0;
              }
          }

      }
    }
  }

}


@media only screen and (max-width: 450px) {

  .ps_calc_galvenais {

    .ps_calc_galvenais_body {

      .ps_calc_shipping_type_row {

          label{

              margin-right: 0;

          }

      }
    }
  }

}
