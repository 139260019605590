// Use this file to overwrite the basic Bootstrap variables and add your own variables
// To overwrite a Bootstrap variable you don´t have to touch the Bootstrap folder.
// Just copy a variable from src/sass/bootstrap4/_variables.scss, paste it here and edit the value.


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1260px
);

$input-placeholder-color: #55565a;

$h1-font-size:                34px;
$h2-font-size:                34px;
$h3-font-size:                23px;
$h4-font-size:                19px;
$h5-font-size:                14px;
$h6-font-size:                44px;
